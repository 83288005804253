import React, { Component } from "react";
import { Row, Col, Form } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import AuthService from "../../shared/services/AuthService";
import bungiiLogo from "../../assets/images/bungii-logo.png";
import storageService from "../../shared/services/StorageService";
import clearAppsettingData from "../../shared/helpers/clearAppsettingData";

/* Redux Import Start */
import { connect } from "react-redux";
import {
	setAccessToken,
	setIsTrackDeliveriesEnabled,
	setIsEapiUser,
	setQuickAccessUser,
	setQuoteOnlyUser,
} from "../../shared/redux/actions/user.actions";
import {
	setActiveTab,
	setKioskMode,
	setDisplayKioskMode,
	setLockedAccess,
	setCommonInitialState,
	setAppSetting,
	setShowLoader,
} from "../../shared/redux/actions/common.actions";
import {
	resetDataOnPageChange,
	resetDataOnLogout,
} from "../../shared/redux/actions/getEstimate.actions";
import { setDeliveryDetailsInitialState } from "../../shared/redux/actions/deliveryDetails.actions";

/* Redux Import End */

class LoginPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {
				password: "",
			},
		};

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange = (event) => {
		try {
			this.setState({ password: event.target.value });
		} catch (err) {
			console.log(err);
		}
	};

	/**
	 * componentWillMount()
	 * This function Executes just before rendering takes place.
	 */
	componentWillMount = async () => {
		try {
			const {
				setDeliveryDetailsInitialState,
				setAppSetting,
				appSetting,
				resetDataOnPageChange,
				setActiveTab,
				setLockedAccess,
				setKioskMode,
				setDisplayKioskMode,
				setCommonInitialState,
				resetDataOnLogout,
				setQuoteOnlyUser,
			} = this.props;
			let data = {
				defaultGeofenceIndex: null,
				defaultGeofence: "",
				timeZone: "",
			};
			if (typeof appSetting === "object") {
				setActiveTab("getEstimate");
				/**
				 * Reset Data
				 */
				setDeliveryDetailsInitialState();
				resetDataOnPageChange(data);
				resetDataOnLogout();
				await setCommonInitialState();
				setKioskMode(null);
				setLockedAccess(false);
				setDisplayKioskMode(false);
				setQuickAccessUser(false);
				setAppSetting("");
				setQuoteOnlyUser(false);

				/**
				 * Clear appSetting data
				 */
				clearAppsettingData();

				localStorage.removeItem("persist:root");
				localStorage.clear();
				sessionStorage.clear();
			}
		} catch (err) {
			console.log(err);
		}
	};

	handleSubmit = async (event) => {
		event.preventDefault();
		try {
			const { partnerLocationReference, setAccessToken, setIsEapiUser, setIsTrackDeliveriesEnabled, setShowLoader, setActiveTab} =
				this.props;
			let data = {
				PartnerLocationReference: partnerLocationReference,
				Password: this.state.password,
			};
			if (
				this.state.password === undefined ||
				this.state.password === "" ||
				this.state.password === null
			) {
				this.setState({
					errors: {
						...this.state.errors,
						password: "Password is required.",
					},
				});
			} else {
				setShowLoader(true);
				let response = await AuthService.isAuthenticated(data);
				if (response.data.AccessToken !== null) {
					setShowLoader(false);
					await storageService.setSessionStorageItem(
						"accessToken",
						response.data.AccessToken
					);
					setAccessToken(response.data.AccessToken);
					setIsTrackDeliveriesEnabled(response.data.IsEnterpriseTrackDeliveriesEnabled);
					setIsEapiUser(response.data.IsEnterprisePartnerPortal);
					if(response.data.IsEnterpriseTrackDeliveriesEnabled){
						setActiveTab('');
						this.props.history.push("/dashboard");
					} else if (response.data.IsEnterprisePartnerPortal) {
						this.props.history.push("/page-not-found");
					} else {
						this.props.history.push("/request-bungii");
					}
				} else {
					setShowLoader(false);
					this.setState({
						errors: {
							...this.state.errors,
							password: response.data.Error.Message,
						},
					});
				}
			}
		} catch (err) {
			console.log(err);
		}
	};

	render = () => {
		return (
			<React.Fragment>
				{/* <img src={Overlay} className="overlay w-100" style={{left: "-8px"}}></img> */}
				<div className="container login-container">
					{/*iPad Portrait view*/}
					{/* <div className="ipad-portrait-view error">
						<div className="row">
							<div className="col-12 col-sm-12 text-center vertically-center">
								<div className="img-wrapper">
									<img
										alt="Bungii-logo"
										className="img-logo"
										src={bungiiLogo}
									/>
								</div>
								<h3>Page not accessible.</h3>
								<p>{languageConstants.portraitMode}</p>
								<div className="img-wrapper">
									<img alt="404" className="img-dimension" src={error_image} />
								</div>
							</div>
						</div>
					</div> */}
					{/*iPad Landscape view*/}
					<div className="height-100">
						<Row className="m-0">
							<Col md="5" lg="7" className="d-none d-md-block img-holder"></Col>
							<Col md="7" lg="5" className="pl-0 pr-0 text-center">
								<div className="login-form">
									<img
										src={bungiiLogo}
										alt="Bungii Logo"
										className="bungiiLogo"
									></img>
									<Form
										className="form-content"
										onSubmit={this.handleSubmit}
										noValidate
									>
										<Form.Group controlId="formBasicPassword">
											<Form.Control
												className={this.state.errors.password ? "invalid" : ""}
												autoComplete="off"
												type="password"
												placeholder="Enter Password"
												value={this.state.value}
												onChange={this.handleChange}
												required
											/>
											<div className="invalid-feedback d-block text-left p-l-18">
												{this.state.errors.password}
											</div>
										</Form.Group>
										<button id="login" className="btn w-100" type="submit">
											Sign In
										</button>
									</Form>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</React.Fragment>
		);
	};
}

export default connect(
	({
		user: { isAuthenticated, partnerLocationReference, accessToken },
		common: { appSetting },
	}) => ({
		isAuthenticated,
		partnerLocationReference,
		accessToken,
		appSetting,
	}),
	(dispatch) => ({
		setAccessToken: (accessToken) => dispatch(setAccessToken(accessToken)),
		setIsTrackDeliveriesEnabled: (flag) => dispatch(setIsTrackDeliveriesEnabled(flag)),
		setIsEapiUser: (flag) => dispatch(setIsEapiUser(flag)),
		setActiveTab: (activeTab) => dispatch(setActiveTab(activeTab)),
		setDeliveryDetailsInitialState: (newValues1) =>
			dispatch(setDeliveryDetailsInitialState(newValues1)),
		setKioskMode: (kioskMode) => dispatch(setKioskMode(kioskMode)),
		setDisplayKioskMode: (displayKioskMode) =>
			dispatch(setDisplayKioskMode(displayKioskMode)),
		setLockedAccess: (lockedAccess) => dispatch(setLockedAccess(lockedAccess)),
		resetDataOnPageChange: (data) => dispatch(resetDataOnPageChange(data)),
		resetDataOnLogout: (data) => dispatch(resetDataOnLogout(data)),
		setCommonInitialState: (commonInitialState1) =>
			dispatch(setCommonInitialState(commonInitialState1)),
		setQuickAccessUser: (quickAccessUser) =>
			dispatch(setQuickAccessUser(quickAccessUser)),
		setAppSetting: (appSetting) => dispatch(setAppSetting(appSetting)),
		setShowLoader: (showLoader) => dispatch(setShowLoader(showLoader)),
		setQuoteOnlyUser: (quoteOnlyUser) =>
			dispatch(setQuoteOnlyUser(quoteOnlyUser)),
	})
)(withRouter(LoginPage));
